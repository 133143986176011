<template>
    <div class="pnf fi wh fl-co-c h1">
        <h1>
            404
        </h1>
        <span class="upper">“Not all those who wander are lost”</span>
    </div>
</template>

<script>
	import { mapActions } from 'vuex'

export default {
	name: '404',
	mounted() {
		this.setSections([this.$refs, this.$route.name])
	},
	methods: {
		...mapActions("GeneralStore", ['setSections']),
	},
}
</script>